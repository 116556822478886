<template>
  <div>
    <b-card header="Профілі СПФМ">
      <b-row
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
      >
        <b-col cols="auto" class="mr-auto p-3">
          <b-button
            variant="success"
            size="sm"
            @click="newItemModal = true"
            class="mb-2"
          >
            <i class="fa fa-dot-circle-o"></i> Додати
          </b-button>
        </b-col>
        <b-col
          v-if="$auth.has($stringConstants('ROLE_OWNER'))"
          cols="auto"
          class="p-3"
        >
          <b-button variant="dark" size="sm" v-b-modal.modal-dump-sql>
            <i class="fa fa-database"></i>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :dark="false"
        :fields="fields"
        :hover="true"
        :striped="true"
        :bordered="true"
        :small="false"
        :fixed="true"
        responsive="sm"
        :items="dataList"
      >
        <template v-slot:cell(accounts)="data">
          <p v-for="item in data.item.accounts" :key="item.id">
            {{ item.bank + " - " + item.iban }}
          </p>
        </template>

        <template v-slot:cell(actions)="data">
          <b-button
            variant="ghost-info link"
            size="sm"
            @click="followToCurrentItem(data.item.id)"
          >
            <i class="fa fa-edit"></i>
          </b-button>

          <b-button
            v-if="
              $auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))
            "
            variant="ghost-danger"
            size="sm"
            @click="deleteItem(data.item)"
          >
            <i class="fa fa-close"></i>
          </b-button>
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="cPartForm"
      title="Профіль СПФМ"
      hide-footer
      size="xl"
      v-model="newItemModal"
      @ok="newItemModal = false"
    >
      <financial-model-form />
    </b-modal>

    <b-modal id="modalRemove" @ok="okRemove">
      <p class="my-4">Впевнені ?</p>
    </b-modal>

    <ExportDbModal ref="exportDbModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FinancialModelForm from "./forms/FinancialModelForm";
import ExportDbModal from "@/entity/FinancialModels/components/ExportDbModal.vue";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_FINANCIAL_MODEL"))
      ) {
        next();
      } else {
        next({ name: "Page404" });
      }
    });
  },
  name: "financial-model-list",
  components: {
    ExportDbModal,
    FinancialModelForm,
  },
  data() {
    return {
      newItemModal: false,
      removeItem: null,
      fields: [
        { key: "id", label: "Ідентифікатор" },
        { key: "name", label: "Назва" },
        { key: "usreou_code", label: "ЄДРПОУ" },
        { key: "accounts", label: "Банківські рахунки" },
        { key: "actions", label: "Дії" },
      ],
    };
  },
  created() {
    this.$store.dispatch("financialModels/pull");
  },
  computed: {
    ...mapGetters({
      allFinancialModels: "financialModels/getAllFinancialModels",
      meFinancialModels: "auth/meFinancialModels",
    }),
    dataList() {
      return this.allFinancialModels.filter((item) =>
        this.meFinancialModels.some((dataItem) => dataItem.id === item.id)
      );
    },
  },
  methods: {
    followToCurrentItem(itemId) {
      this.$router.push({ name: "financial-model", params: { id: itemId } });
    },
    okRemove() {
      this.$store.dispatch("financialModels/delete", this.removeItem);
    },
    deleteItem(item) {
      this.removeItem = item;
      this.$root.$emit("bv::show::modal", "modalRemove");
    },
  },
};
</script>
