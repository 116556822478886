<template>
  <b-modal id="modal-dump-sql" hide-footer title="Вивантаження даних">
    <p class="mb-4">
      Ви отримаєте на свою пошту файл архіву з даними контрагентів та їх
      договорів. Натискаючи <strong>Замовити</strong>, ви берете на себе
      відповідальність за збереження (захищеність) всієї вивантаженої інформації
      з AML.point
    </p>
    <div>
      <b-button @click="databaseDump" variant="success">Замовити</b-button>
    </div>
  </b-modal>
</template>

<script>
import api from "@/api/api";

export default {
  methods: {
    databaseDump() {
      api
        .databaseDump()
        .then(() => {
          this.$snotify.success(
            "Дамп бази даних буде надіслано на вашу пошту!"
          );
          this.$bvModal.hide("modal-dump-sql");
        })
        .catch(() => {
          this.$snotify.error("Помилка створення дампу бази даних!");
        });
    },
  },
};
</script>
